<template>
  <div>
    <app-layout>
      <template v-slot:header>
        <Header :title="$t('attendance_entry')" :isFilter="false"/>
      </template>
      <template v-slot:header-mobile>
        <HeaderMobile :title="$t('attendance_entry')" :isFilter="false"/>
      </template>
      <div class="d-flex flex-column flex-lg-row full-height overflow-hidden">
        <div class="width-300 mr-3 d-none d-lg-block" style="min-width: 300px;">
          <div class="sections d-flex flex-column">
            <h5>{{ $t('sections') }}</h5>
            <div class="filter">
              <b-form-input v-model="sectionFilter" size="lg" :placeholder="$t('filter')"/>
            </div>
            <div class="flex-grow-1 section-list" v-if="sectionLoading">
              <loading :show="sectionLoading"/>
            </div>
            <div class="flex-grow-1 section-list d-none d-lg-block" id="section-list" v-else>
              <div class="text-center" v-if="filteredSections.length == 0">
                {{ $t('no_result_2') }}
              </div>
              <div class="section cursor-pointer"
                   :class="section_id && section_id === item.section_id ? 'selected-item' : ''"
                   :id="'section-' + item.section_id" v-for="(item, key) in filteredSections"
                   :key="'section-' + item.section_id + key" :ref="'section-' + item.section_id"
                   @click="section_id = item.section_id" v-else>
                <div class="name">{{ item.course_name }}</div>
                <div class="instructor">{{ item.instructor }}</div>

                <div class="other">
                  <b-badge variant="dark">{{ item.course_code }}</b-badge>
                  <b-badge variant="dark" v-if="item.section">Section: {{ item.section }}</b-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="width-300 mr-3 d-none d-lg-block" style="min-width: 300px;">
          <div class="weeks d-flex flex-column">
            <h5>{{ $t('day') }} / {{ $t('date') }}</h5>
            <div class="flex-grow-1 week-list" v-if="dailyLoading">
              <loading :show="dailyLoading"/>
            </div>
            <div class="flex-grow-1 week-list" v-else>
              <div class="text-center" v-if="days.length == 0">
                {{ $t('no_result_2') }}
              </div>
              <div class="week cursor-pointer" :class="day && day == item.date ? 'selected-item' : ''"
                   v-for="(item, key) in days" :key="key" @click="selectDay(item.date)" v-else>
                <div class="d-flex">
                  <div class="flex-grow-1 d-flex flex-column align-items-center">
                    <span>{{ key + 1 }}</span>
                    <span>{{ $t('day') }}</span>
                  </div>
                  <div class="flex-grow-1 d-flex flex-column align-items-center">
                    <span>{{ item.date }}</span>
                    <span>{{ $t('date') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="h-100 d-flex flex-column">
            <div class="mb-3 d-block d-lg-none">
              <h5>{{ $t('sections') }}</h5>
              <b-form-select :options="sectionsOptions" v-model="section_id"
                             @input="selectSection(section_id)"/>
            </div>
            <div class="mb-3 d-block d-lg-none">
              <h5>{{ $t('day') + ' / ' + $t('date') }}</h5>
              <b-form-select :options="dailyOptions" v-model="day" @input="selectSection(section_id)"/>
            </div>
            <h5>{{ $t('students') }}</h5>
            <div class="students d-flex flex-column">
              <div class="flex-grow-1 week-list" v-if="studentLoading">
                <loading :show="studentLoading"/>
              </div>
              <div v-else>
                <div class="text-center" v-if="students.length == 0">
                  {{ $t('no_result_2') }}
                </div>
                <div v-else>
                  <b-table :empty-filtered-text="this.$t('table_not_found')"
                           :empty-text="this.$t('table_is_empty')" bordered striped responsive
                           :items="students" :fields="studentsField"
                           class="mb-0 table-dropdown no-scrollbar border rounded">
                    <template #cell(student)="row">
                      <span class="d-block d-lg-none">{{ row.item.student_number }}<br></span>
                      <span>{{ row.item.name }} {{ row.item.surname }}<br></span>
                      <span>{{ row.item.class.toUpper() }}<br></span>
                      <span>{{ row.item.program_name }}<br></span>
                    </template>
                    <template #cell(status)="row">
                      <b-form-select :options="hours" v-model="students[row.index].status"/>
                    </template>
                  </b-table>
                  <div class="d-flex justify-content-between mt-4">
                    <div>
                      <b-form-select :options="hours" v-model="status" class="mb-2"/>

                      <b-button variant="primary" @click="allSelect">
                        {{ $t('select_all') }}
                      </b-button>
                    </div>

                    <b-button variant="primary" @click="save()"
                              v-if="this.checkPermission('attendance_storeattendance')">
                      {{ $t('save') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-layout>
  </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

// Component
import Loading from '@/components/elements/Loading2'

// Services
import AttendanceService from '@/services/AttendanceService'
import AttendanceEntryService from '@/services/AttendanceEntryService'
import moment from "moment/moment";

export default {
  components: {
    AppLayout,
    Header,
    HeaderMobile,
    Loading
  },
  metaInfo() {
    return {
      title: this.$t('attendance_entry')
    }
  },
  computed: {
    filteredSections() {
      if (this.sectionFilter) {
        return this.sections.filter(item => {
          let courseCodeValue = item.course_code.toUpperCase().replaceAll(/[İi]/ig, 'I');
          let courseNameValue = item.course_name.toUpperCase().replaceAll(/[İi]/ig, 'I');

          let filterValue = this.sectionFilter.toUpperCase().replaceAll(/[İi]/ig, 'I');

          return courseCodeValue.indexOf(filterValue) > -1 || courseNameValue.indexOf(filterValue) > -1
        })
      } else {
        return this.sections;
      }
    }
  },
  data() {
    return {
      status: null,
      // Sections
      sectionLoading: false,
      sectionFilter: null,
      sections: [],
      sectionsOptions: [],
      section: {},
      section_id: null,

      // Weeks
      dailyLoading: false,
      dailyOptions: [],
      days: [],
      day: null,

      // Students
      studentLoading: false,
      students: [],
      studentsField: [
        {
          key: 'student_number',
          label: this.$t('student_number').toUpper(),
          class: 'd-none d-lg-block',
          sortable: true
        },
        {
          key: 'student',
          label: this.$t('student').toUpper(),
          sortable: true
        },
        {
          key: 'status',
          label: this.$t('attendance').toUpper(),
          sortable: false
        }
      ],

      // Hours
      hours: [
        {value: "A", text: this.$t('absent')},
        {value: "P", text: this.$t('present')},
        {value: "E", text: this.$t('excused')},
        {value: "H", text: this.$t('holiday')},
        {value: "T", text: this.$t('Tardy')},
        {value: "D", text: 'Delete'}
      ]
    }
  },
  watch: {
    section_id: function (newValue) {
      this.selectSection(newValue)
      this.getDays();
    },
    day: function () {
      this.getStudents();
    }
  },
  created() {
    this.getSections().then(() => {
      if (this.$route.params.sectionId) {
        let section = this.$refs['section-' + this.$route.params.sectionId];
        section[0].click();

        let sectionListTop = document.getElementsByClassName('section-list')[0].offsetTop
        let sectionTop = document.getElementById('section-' + this.$route.params.sectionId).offsetTop
        document.getElementsByClassName('section-list')[0].scrollTop = sectionTop - (sectionListTop + 20);
      }
    })
  },
  methods: {
    allSelect() {
      this.students.map(student => {
        student.status = this.status
      })
    },
    // Sections
    async getSections() {
      this.sectionLoading = true
      await AttendanceEntryService.getAll().then((response) => {
        let data = response.data.data;
        this.sections = data
        this.sectionsOptions = []
        data.forEach((item) => {
          this.sectionsOptions.push({
            value: item.section_id,
            text: item.course_code + ' - ' + item.course_name + ' - Section: ' + item.section
          })
        })
      }).catch((e) => {
        this.showErrors(e)
      }).finally(() => {
        this.sectionLoading = false
      })
    },
    selectSection(section_id) {
      this.section_id = section_id
      this.section = this.sections.find(item => item.section_id == section_id)
    },

    // Weeks
    getDays() {
      this.days = [];
      this.day = null;
      this.students = [];
      if (this.section_id) {
        this.dailyLoading = true;
        AttendanceService.getAttendanceDays(this.section_id).then((response) => {
          let data = response.data.data
          this.days = data
          data.forEach((item) => {
            this.dailyOptions.push({
              value: item.date,
              text: item.date + ' .' + this.$t('day') + ' - ' + this.toLocaleDate(item.date)
            })
          })
        }).catch((e) => {
          this.showErrors(e)
        }).finally(() => {
          this.dailyLoading = false
        })
      }
    },
    selectDay(day) {
      this.day = day
    },

    // Students
    getStudents() {
      this.students = [];
      if (this.section_id && this.day) {
          let day = moment(this.day).format('MM-DD-YYYY');
        this.studentLoading = true;
        AttendanceService.getSectionStudents(this.section_id, {params: {day:day}}).then((response) => {
          let data = response.data.data
          this.students = data.map(item => ({...item, status: ""}))
        }).then(() => {
          AttendanceService.getAttendanceDayStudents(this.section_id, day).then((response) => {
            let data = response.data.data;
            Object.keys(data).map((key) => {

              let index = this.students.findIndex(item => item.student_program_id == key)
              if (index > -1 && data[key][0].status) {
                this.students[index].status = data[key][0].status
              }
            })
            this.students.map((item, index)=>{
              if(item.is_passive==true && !data[item.student_program_id.toString()]){
                  delete this.students[index];
              }
            });
          }).catch((e) => {
            this.showErrors(e);
          }).finally(() => {
              this.studentLoading = false
          })
        }).catch((e) => {
          this.showErrors(e);
        })
      }
    },

    // Save
    save() {
      let attendances = [];
      this.students.forEach((item) => {
        if (item.status) {
          attendances.push({
            student_program_id: item.student_program_id,
            status: item.status
          })
        }
      })

      let data = {
        section_id: this.section_id,
        date: this.day,
        attendances: attendances
      }
      AttendanceService.saveDaily(data).then(response => {
        this.$toast.success(this.$t('successfully_updated'));
      }).catch((e) => {
        this.showErrors(e);
      })
    }
  }
}
</script>

<style scoped>
/* Sections */
.sections {
  height: 100%;
}

.sections .filter {
  margin-bottom: 5px;
}

.sections .filter input {
  border-radius: 5px;
}

.sections .section-list {
  border: 1px solid #e9ecef;
  background: #f9f9f9;
  padding: 18px 15px;
  border-radius: 4px;
  overflow-y: auto;
}

.sections .section-list .section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  border: 1px solid #e9ecef;
  padding: 15px 15px 10px 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #fff;
}

.selected-item {
  background: #00aeef !important;
  color: #fff !important;
}

.sections .section-list .section .name {
  font-size: 15px;
  line-height: 16px;
}

.sections .section-list .section .instructor {
  font-size: 10px;
  line-height: 16px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.sections .section-list .section .other {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.sections .section-list .section .other .badge {
  border: 1px solid #e9ecef;
  background: #f1f1f1;
  padding: 4px 6px;
  border-radius: 2px;
  font-weight: 500;
}

/*  Weeks */
.weeks {
  height: 100%;
}

.weeks .week-list {
  border: 1px solid #e9ecef;
  background: #f9f9f9;
  padding: 18px 15px;
  border-radius: 4px;
  overflow-y: auto;
}

.weeks .week-list .week {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e9ecef;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #fff;
}

.weeks .week-list .week span:first-child {
  font-size: 16px;
  margin-bottom: 2px;
}

.weeks .week-list .week span:last-child {
  font-size: 14px;
}

/* Students */
.students {
  height: 100%;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 18px 15px;
  overflow-y: auto;
}
</style>
